import React, { FunctionComponent, Fragment, useEffect, useState } from 'react';
import { Theme as AugmentedTheme, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TodaysCatchSectionCarousel } from './Carousel/Carousel';
import { TodaysCatchSectionRadioButtons as RadioButtons } from './RadioButtons/RadioButtons';
import { Carousel, FishImagesSummary } from '../../types/models';
import moment from 'moment';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { getHumanLongDate } from '../../logic/dateUtil';
import { FishImageStatusFirebase } from '../../types/modelsFirebase';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  todaysCatchText: {
    marginRight: theme.remSpacing(3)
  },
  todaysCatchWrapper: {
    minHeight: theme.remSpacing(5),
    marginBottom: theme.remSpacing(3)
  },
  radioButtons: {
    marginBottom: theme.remSpacing(1)
  }
}));

interface Props {
  carousel: Carousel;
  fishImagesSummary?: FishImagesSummary;
  handleCurrentRadioValue: (statuses: React.SetStateAction<FishImageStatusFirebase[] | undefined>) => void;
  handleCarouselEnding: Function;
}

export const TodaysCatchSection: FunctionComponent<Props> = ({
  carousel,
  fishImagesSummary,
  handleCurrentRadioValue,
  handleCarouselEnding
}: Props) => {
  const defaultSectionLabel = Localization.getInst().localizedString(LocalizationKey.todaysCatch);
  const [sectionLabel, setSectionLabel] = useState<string>(defaultSectionLabel);

  const handleSectionLabel = () => {
    if (undefined !== fishImagesSummary) {
      const label = moment(fishImagesSummary.lastImageDate.toDate()).isSame(moment(), 'day')
        ? defaultSectionLabel
        : Localization.getInst().localizedString(
            LocalizationKey.catchFrom,
            `${getHumanLongDate(fishImagesSummary.lastImageDate.toDate())}`
          );
      setSectionLabel(label);
    }
  };

  useEffect(() => {
    handleSectionLabel();
  }, [fishImagesSummary]);

  const styles = useStyles();
  return (
    <Fragment>
      <Box className={styles.todaysCatchWrapper}>
        {fishImagesSummary && (
          <Fragment>
            <Typography className={styles.todaysCatchText} variant="h3" component="span">
              {sectionLabel}
            </Typography>
            <Typography variant="h4" component="span">
              {fishImagesSummary.lastImagesDateCatch} {Localization.getInst().localizedString(LocalizationKey.fishes)}
            </Typography>
          </Fragment>
        )}
      </Box>
      {/*<Box className={styles.radioButtons}>
        <RadioButtons handleCurrentRadioValue={handleCurrentRadioValue} />
      </Box>*/}
      <TodaysCatchSectionCarousel
        key={carousel.id}
        carouselData={carousel}
        handleCarouselEnding={handleCarouselEnding}
      />
    </Fragment>
  );
};
