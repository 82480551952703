import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Theme as AugmentedTheme } from '@material-ui/core';

interface Props {
  x?: number;
  y?: number;
  min: number;
  max: number;
  minColor: string;
  maxColor: string;
  value?: number;
}

/**
 * Returns on graph labels for maximum and minimum value.
 * Each chart value is checked against min and max
 * and if its equal to any of those corresponding label is drawn on chart
 */
export const WelfareGraphLabelMinMax: React.FunctionComponent<Props> = ({
  value,
  min,
  max,
  minColor,
  maxColor,
  x,
  y
}) => {
  const theme: AugmentedTheme = useTheme();
  const minMaxColor = max == value ? maxColor : min == value ? minColor : null;
  return (
    (minMaxColor !== null && (
      <text x={x} y={y} dy={-4} fill={minMaxColor} style={theme.typography.h5} textAnchor="middle">
        {value && Math.round(value * 100) / 100}
      </text>
    )) ||
    null
  );
};
