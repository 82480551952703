import React, { useState, useEffect, useMemo } from 'react';
import { WelfareGraph, LineChartItem } from '../WelfareGraph/WelfareGraph';
import { DayRangeButton } from '../DayRangeButton/DayRangeButton';
import { DayRangeAsString } from '../DayRangeSelector/DayRangeSelectorInterface';
import { Theme as AugmentedTheme, Box, Typography, Fade, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import { StatsRow, FishImage, Carousel, Cage, FishImagesSummary, StatsIntervalStatus } from '../../types/models';
import { TodaysCatchSection } from '../TodaysCatchSection/TodaysCatchSection';
import { calculateLocalityFishImagesSummary } from '../../logic/cageUtils';
import { CagesNavigation } from '../CagesNavigation/CagesNavigation';
import { Timestamp } from '@google-cloud/firestore';
import { MultipleSelect, MultipleSelectItem } from '../MultipleSelect/MultipleSelect';
import { METRICS } from '../../constants/metrics';
import { BoxCenterContent } from '../BoxCenterContent/BoxCenterContent';
import { NoDataMessageWithWarningIcon } from '../NoDataMessageWithWarningIcon/NoDataMessageWithWarningIcon';
import { supportEmailAddress } from '../../constants/supportEmailAddress';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { InfoPopup } from '../InfoPopup/InfoPopup';
import { FishImageStatusFirebase } from '../../types/modelsFirebase';
import { WeightGraph } from '../WeightGraph/WeightGraph';
import { WeightDistributionGraph } from '../WeightGraph/WeightDistributionGraph';
import { TTabs } from '../../components/LocalityDetails/LocalityDetails';
import { StatsIntervalButtons } from '../StatsIntervalButtons/StatsIntervalButtons';
import { ExportCsvButton } from '../ExportCsvButton/ExportCsvButton';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: (props: { cagesLength: number }) => ({
    height: 750,
    minWidth: 360,
    maxWidth: 1000, //743,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 360,
      height: 650,
      padding: theme.remSpacing(5, 0)
    },
    margin: theme.remSpacing(0, 6, 6, 6),
    padding: theme.remSpacing(10, 0, 5, 0),
    boxShadow: 'unset',
    background: props.cagesLength > 0 ? theme.colors.white08 : theme.colors.white
  }),
  contentContainer: {
    position: 'relative',
    paddingLeft: theme.remSpacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.remSpacing(5)
    }
  },
  topBarContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  topBarTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column'
  },
  statsSelectContainer: {
    zIndex: 999,
    position: 'relative'
  },
  statsSelectInnerContainer: {
    zIndex: 999,
    right: '100px',
    top: '25px',
    [theme.breakpoints.down('sm')]: {
      right: '16px',
      top: '25px'
    },
    position: 'absolute'
  },
  exportCsvButtonContainer: {
    zIndex: 999,
    right: '30px',
    top: '25px',
    [theme.breakpoints.down('sm')]: {
      right: '16px',
      top: '75px'
    },
    position: 'absolute'
  },
  dayRangeButtonContainer: {
    display: 'flex',
    paddingRight: theme.remSpacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.remSpacing(2)
    }
  },
  graphContainer: {
    padding: theme.remSpacing(6, 15, 2, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.remSpacing(6, 0, 0, 0)
    }
  },
  infoPopupContainer: {
    float: 'right',
    textAlign: 'right',
    paddingRight: theme.remSpacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.remSpacing(6)
    }
  },
  todaysCatchContainer: {
    paddingTop: theme.remSpacing(4)
  },
  noDataTextContainer: {
    width: 440,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.remSpacing(6, 6, 6, 6)
  },
  tabLinkContainer: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 500,
    cursor: 'pointer',
    marginBottom: theme.remSpacing(8),
    '& a': {
      color: theme.colors.oxfordBlue,
      margin: theme.remSpacing(0, 8),
      paddingBottom: theme.remSpacing(1)
    },
    '& a:hover, a:visited, a:link, a:active': {
      textDecoration: 'none'
    }
  },
  tabLinkSelected: {
    borderBottom: `3px solid ${theme.colors.oxfordBlue}`,
    fontWeight: 600
  }
}));

interface Props {
  firebaseInit: boolean;
  stats: StatsRow[] | undefined;
  cages: Cage[];
  selectedCage: Cage | undefined;
  statsInterval: StatsIntervalStatus;
  queryDates: DayRangeAsString | null;
  setSelectedCage: (value: React.SetStateAction<Cage | undefined>) => void;
  fetchFishImages: (
    cageId?: string,
    statuses?: FishImageStatusFirebase[],
    untilTimestamp?: Timestamp
  ) => Promise<FishImage[]>;
  setStatsInterval: (statsInterval: StatsIntervalStatus) => void;
  setQueryDates: (value: React.SetStateAction<DayRangeAsString | null>) => void;
  localityName: string;
  displayBiomassGraph: boolean;
  tab: TTabs;
  onTabChange: (tab: TTabs) => void;
}

export const MainPanel: React.FunctionComponent<Props> = ({
  firebaseInit,
  stats,
  cages,
  selectedCage,
  statsInterval,
  queryDates,
  setSelectedCage,
  fetchFishImages,
  setQueryDates,
  setStatsInterval,
  localityName,
  displayBiomassGraph,
  tab,
  onTabChange
}: Props) => {
  const [fishImagesStatuses, setFishImagesStatuses] = useState<FishImageStatusFirebase[] | undefined>(undefined);
  const [cageFishImagesSummary, setCageFishImagesSummary] = useState<FishImagesSummary | undefined>(undefined);
  const [localityFishImagesSummary, setLocalityFishImagesSummary] = useState<FishImagesSummary | undefined>(undefined);
  const [carousel, setCarousel] = useState<Carousel | undefined>(undefined);
  const [activeMetricsIds, setActiveMetricsIds] = useState<string[] | []>([]);
  const styles = useStyles({ cagesLength: cages.length });
  const selectedCageId = undefined != selectedCage ? selectedCage.cageId : undefined;

  const handleCageFishImagesSummary = () => {
    const cage = cages.find((cage) => cage.cageId == selectedCageId);
    if (cage && cage.fishImagesSummary) {
      setCageFishImagesSummary(cage.fishImagesSummary);
    }
  };

  const handleInitialImages = async () => {
    const images = await fetchFishImages(selectedCageId, fishImagesStatuses);
    const idPrefix = undefined != fishImagesStatuses ? fishImagesStatuses.join('_') : 'all';
    const idPostfix = undefined != selectedCageId ? selectedCageId : 'location';
    const id = idPrefix + '_' + idPostfix;
    setCarousel({ id, images });
  };

  const handleAddNewImages = async (images: FishImage[]) => {
    const lastImageIndex = images.length - 1;
    const lastImage: FishImage = images[lastImageIndex];
    const newImages = await fetchFishImages(selectedCageId, fishImagesStatuses, lastImage.date);
    images.pop();
    return images.concat(newImages);
  };

  const handleCarouselEnding = async () => {
    if (!carousel) {
      return;
    }
    const images = await handleAddNewImages(carousel.images);
    setCarousel({ id: carousel.id, images });
  };

  const getCurrentFishImagesSummary = () =>
    undefined !== selectedCageId ? cageFishImagesSummary : localityFishImagesSummary;

  const hasStatsForMetric = (metricId: string) => stats && stats.find((statRow) => statRow[metricId]);

  const availableMetrics = METRICS.filter((metric) => hasStatsForMetric(metric.id));

  const getMetricsSelectItems = () =>
    availableMetrics
      .reduce((config, metric) => {
        if (metric.selectorProps) {
          config.push({
            id: metric.id,
            index: metric.selectorProps.index,
            name: metric.name,
            active: metric.selectorProps.active
          });
        }
        return config;
      }, [] as MultipleSelectItem[])
      .sort((a, b) => a.index - b.index);

  const getMetricsLineChartsItems = () =>
    availableMetrics.reduce((config, metric) => {
      if (metric.lineChartProps) {
        config.push({ id: metric.id, name: metric.name, ...metric.lineChartProps });
      }
      return config;
    }, [] as LineChartItem[]);

  useEffect(() => {
    handleInitialImages();
  }, [firebaseInit, fishImagesStatuses, selectedCageId]);

  useEffect(() => {
    if (undefined !== selectedCageId) {
      handleCageFishImagesSummary();
    }
  }, [selectedCageId]);

  useMemo(() => {
    const localityFishImagesSummary = calculateLocalityFishImagesSummary(cages);
    setLocalityFishImagesSummary(localityFishImagesSummary);
  }, [cages]);

  const noDataForLocationMessage = () => {
    return (
      <BoxCenterContent>
        <div className={styles.noDataTextContainer}>
          <Typography variant="h3" component="div">
            {Localization.getInst().localizedString(
              LocalizationKey.weAreHavingIssuesFetchingDataForThisLocationAtTheMoment
            )}
          </Typography>
          <Typography variant="h4" component="div">
            {Localization.getInst().localizedString(
              LocalizationKey.thisMayBeDueToTheNetworkConnectionsOrTheCameraLensesMayNeedToBeCleaned
            )}
            <br />
            {Localization.getInst().localizedString(LocalizationKey.cleanTheLensesAndTryAgainLaterOrContactUsAt)}
            <br />
            {supportEmailAddress}
          </Typography>
        </div>
      </BoxCenterContent>
    );
  };

  const noDataForCageMessage = () => {
    return (
      <BoxCenterContent>
        <NoDataMessageWithWarningIcon cageId={selectedCageId} />
      </BoxCenterContent>
    );
  };

  return (
    <Card className={styles.container}>
      {cages.length > 0 && (
        <Fade in={stats !== undefined && carousel !== undefined}>
          <Box>
            <Box className={styles.contentContainer}>
              {displayBiomassGraph && (
                <Box className={styles.tabLinkContainer}>
                  <Link
                    className={tab === 'welfare' ? styles.tabLinkSelected : ''}
                    onClick={() => onTabChange('welfare')}
                  >
                    {Localization.getInst().localizedString(LocalizationKey.welfareTab, localityName)}
                  </Link>
                  <Link
                    className={tab === 'biomass' ? styles.tabLinkSelected : ''}
                    onClick={() => onTabChange('biomass')}
                  >
                    {Localization.getInst().localizedString(LocalizationKey.biomassTab, localityName)}
                  </Link>
                </Box>
              )}
              <Box className={styles.topBarContainer}>
                <Box className={styles.topBarTitle}>
                  <Typography variant="h1">
                    {!selectedCage &&
                      Localization.getInst().localizedString(
                        (tab === 'welfare' && LocalizationKey.welfareInLocalityArea) ||
                          LocalizationKey.biomassInLocalityArea,
                        localityName
                      )}
                    {selectedCage &&
                      Localization.getInst().localizedString(
                        (tab === 'welfare' && LocalizationKey.welfareInLocalityAndCage) ||
                          LocalizationKey.biomassInLocalityAndCage,
                        selectedCage.name,
                        localityName
                      )}
                  </Typography>
                </Box>
                <Box className={styles.dayRangeButtonContainer}>
                  <StatsIntervalButtons setStatsInterval={setStatsInterval} />
                  <DayRangeButton statsInterval={statsInterval} setQueryDates={setQueryDates} />
                </Box>
              </Box>
              {/*
              {tab === 'welfare' && (
                <Box className={styles.statsSelectContainer}>
                  <Box className={styles.statsSelectInnerContainer}>
                    {stats && stats.length > 0 && (
                      <MultipleSelect items={getMetricsSelectItems()} handleSetChecked={setActiveMetricsIds} />
                    )}
                  </Box>
                  {stats && stats.length > 0 && (
                    <Box className={styles.exportCsvButtonContainer}>
                      <ExportCsvButton
                        stats={stats}
                        activeLineChartItemsIds={activeMetricsIds}
                        lineChartItems={getMetricsLineChartsItems()}
                        fileName={queryDates ? `${queryDates.from}--${queryDates.to}.xlsx` : `stats.xlsx`}
                      />
                    </Box>
                  )}
                </Box>
              )}
              */}
              <Box className={styles.graphContainer}>
                {stats === undefined && <Box height="350px"></Box>}
                {stats && stats.length == 0 && <Box height="350px">{noDataForCageMessage()}</Box>}
                {stats && stats.length > 0 && tab == 'welfare' && (
                  <WelfareGraph
                    stats={stats}
                    statsInterval={statsInterval}
                    activeLineChartItemsIds={activeMetricsIds}
                    lineChartItems={getMetricsLineChartsItems()}
                  />
                )}
                {displayBiomassGraph && tab === 'biomass' && stats && stats.length === 1 && (
                  <WeightDistributionGraph stats={stats[0]} />
                )}
                {displayBiomassGraph && tab === 'biomass' && stats && stats.length > 1 && <WeightGraph stats={stats} statsInterval={statsInterval} />}
              </Box>
              {/*
              <Box className={styles.infoPopupContainer}>
                <InfoPopup tab={tab} />
              </Box>
              */}
              <Box className={styles.todaysCatchContainer}>
                {carousel && carousel.images.length > 0 && (
                  <TodaysCatchSection
                    carousel={carousel}
                    fishImagesSummary={getCurrentFishImagesSummary()}
                    handleCurrentRadioValue={setFishImagesStatuses}
                    handleCarouselEnding={handleCarouselEnding}
                  />
                )}
                {carousel && carousel.images.length == 0 && <Box height="204px">{noDataForCageMessage()}</Box>}
              </Box>
            </Box>
            {cages.length > 1 && (
              <CagesNavigation cages={cages} selectedCage={selectedCage} setSelectedCage={setSelectedCage} />
            )}
          </Box>
        </Fade>
      )}
      {cages.length == 0 && noDataForLocationMessage()}
    </Card>
  );
};
