import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  TooltipProps,
  Bar
} from 'recharts';
import { useTheme } from '@material-ui/styles';
import { Theme as AugmentedTheme, useMediaQuery } from '@material-ui/core';
import { WelfareGraphLabelMinMax } from '../WelfareGraph/Label/MinMax';
import { StatsRow, StatsIntervalStatus } from '../../types/models';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { getHumanShortDate, getHumanWeek } from '../../logic/dateUtil';
import { METRICS } from '../../constants/metrics';
import { WeightDistributionGraph } from './WeightDistributionGraph';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  popupContainer: {
    width: '800px',
    background: '#FFFFFF',
    borderRadius: '2px',
    borderColor: 'black',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  popupTitle: {
    margin: theme.remSpacing(4, 4, 2, 4)
  },
  popupRemark: {
    margin: theme.remSpacing(0, 4, 0, 4),
    fontSize: '0.75rem',
  }
}));

interface Props {
  stats: StatsRow[];
  statsInterval: StatsIntervalStatus;
}

export const WeightGraph: React.FunctionComponent<Props> = ({ stats, statsInterval }: Props) => {
  // eslint-disable-next-line
  const getMin = (data: { weight: number }[]): number =>
    data.reduce(
      (min, object) => (object.weight < min ? object.weight : min),
      data[0].weight
    );
  // eslint-disable-next-line
  const getMax = (data: { weight: number }[]): number =>
    data.reduce(
      (max, object) => (object.weight > max ? object.weight : max),
      data[0].weight || 0
    );

  const weightMetric = METRICS.find((m) => m.id == 'weight');
  const theme: AugmentedTheme = useTheme();
  const styles = useStyles();

  const getLegendWidth = () => {
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const custom1Up = useMediaQuery('(min-width:1050px)');
    const custom2Up = useMediaQuery('(min-width:1150px)');
    let legendWidth = 140;
    if (mdUp) {
      legendWidth = 190;
    }
    if (custom1Up) {
      legendWidth = 300;
    }
    if (custom2Up) {
      legendWidth = 350;
    }
    if (lgUp) {
      legendWidth = 400;
    }
    return legendWidth;
  };
  return (
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart
        width={600}
        height={350}
        data={stats.map((s) => ({
          weight: s.weight,
          date: s.date,
          fish: s.fish
        }))}
      >
        <defs>
          <linearGradient id="colorWelfare" x1="0" y1="0" x2="0" y2="1">
            <stop offset="25%" stopColor={theme.colors.seaGreen} stopOpacity={0.625} />
            <stop offset="95%" stopColor={theme.colors.oxfordBlue} stopOpacity={0.625} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke={theme.colors.oxfordBlue01} />
        <XAxis
          dataKey="date"
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          stroke={theme.colors.oxfordBlue04}
          tickFormatter={(item) => {
            return statsInterval == StatsIntervalStatus.weekly
              ? `${Localization.getInst().localizedString(LocalizationKey.week)} ${getHumanWeek(item)}`
              : getHumanShortDate(item);
          }}
        />
        <YAxis
          yAxisId="left"
          label={{
            value: Localization.getInst().localizedString(LocalizationKey.averageWeightLabel),
            angle: -90,
            position: 'insideBottomLeft',
            offset: 10,
            style: theme.typography.h6
          }}
          tickLine={false}
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          ticks={[...Array(Math.round(getMax(stats) / 100) + 1).keys()].map((i) => i * 100)}
          domain={[0, Math.round(getMax(stats)) + 1]}
          stroke={theme.colors.oxfordBlue04}
        />
        {weightMetric && weightMetric.lineChartProps && (
          <Line
            key={weightMetric.id}
            name={weightMetric.name}
            yAxisId="left"
            type="monotone"
            dot={
              weightMetric.lineChartProps.dotColor
                ? { fill: weightMetric.lineChartProps.dotColor, strokeWidth: 0, r: 2 }
                : false
            }
            dataKey={weightMetric.id}
            legendType={weightMetric.lineChartProps.legendType}
            stroke={weightMetric.lineChartProps.color}
            strokeWidth={1.25}
            label={
              weightMetric.lineChartProps.minMaxLabelProps ? (
                <WelfareGraphLabelMinMax
                  max={getMax(stats)}
                  min={getMin(stats)}
                  {...weightMetric.lineChartProps.minMaxLabelProps}
                />
              ) : (
                false
              )
            }
          />
        )}
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: Localization.getInst().localizedString(LocalizationKey.scannedFishCount),
            angle: -90,
            position: 'insideRight',
            offset: 10,
            style: theme.typography.h6
          }}
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          stroke={theme.colors.transparent}
          domain={[0, 'dataMax']}
          tickLine={false}
        />
        <Bar
          name={Localization.getInst().localizedString(LocalizationKey.scannedFishCountBar)}
          yAxisId="right"
          dataKey="fish"
          fill={theme.colors.oxfordBlue02}
          stroke={0}
          strokeWidth={0}
        />
        <Legend
          align="left"
          verticalAlign="top"
          iconSize={16}
          height={55}
          width={getLegendWidth()}
          wrapperStyle={{ ...theme.typography.h6 }}
        />
        <Tooltip
          wrapperStyle={{ top: 280, zIndex: 1000, left: 0 }}
          content={(props: TooltipProps) => {
            const { label } = props;
            const stat = stats.find((s) => s.date === label) || stats[0];
            const fishStat = stat.fish;
            const weightStat = typeof stat.weight === 'number' ?
              Math.round(stat.weight * 100) / 100 :
              stat.weight
            const labelFormat = statsInterval == StatsIntervalStatus.weekly
              ? `${Localization.getInst().localizedString(LocalizationKey.week)} ${getHumanWeek(label)}`
              : getHumanShortDate(label);
            const binSize = '100';
            return (
              <div className={styles.popupContainer}>
                <p className={styles.popupTitle}>
                  {Localization.getInst().localizedString(LocalizationKey.biomassPopupDetails1, `${label}`)}<br></br>
                  {Localization.getInst().localizedString(LocalizationKey.biomassPopupDetails2, `${fishStat}`, `${weightStat}`)}
                </p>
                <WeightDistributionGraph stats={stat} height={150} />
                <p className={styles.popupRemark}>
                  {Localization.getInst().localizedString(LocalizationKey.biomassPopupDetails3, `${binSize}`)}
                </p>
              </div>
            );
          }}
        />
        <CartesianGrid stroke={theme.colors.whiteSmoke} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
