import React from 'react';
import { Button } from '@material-ui/core';

interface Props<P> {
  children: JSX.Element;
  className?: string;
  value: P;
  onButtonClick: (value: P) => void;
}

export const ButtonWithValue = <P extends {}>(props: Props<P>) => {
  const _onClick = () => {
    props.onButtonClick(props.value);
  };

  return (
    <Button className={props.className} onClick={_onClick}>
      {props.children}
    </Button>
  );
};
