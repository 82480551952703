import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LegendType,
  Tooltip,
  LabelList
} from 'recharts';
import { useTheme } from '@material-ui/styles';
import { Theme as AugmentedTheme, useMediaQuery } from '@material-ui/core';
import { WelfareGraphLabelMinMax } from './Label/MinMax';
import { StatsRow, StatsIntervalStatus } from '../../types/models';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { getHumanShortDate, getHumanWeek } from '../../logic/dateUtil';

export interface LineChartItem {
  id: string;
  name: string;
  color: string;
  legendType: LegendType;
  dotColor?: string;
  minMaxLabelProps?: { minColor: string; maxColor: string };
}

interface Props {
  stats: StatsRow[];
  statsInterval: StatsIntervalStatus;
  lineChartItems: LineChartItem[];
  activeLineChartItemsIds: string[];
}

export const WelfareGraph: React.FunctionComponent<Props> = ({
  stats,
  statsInterval,
  activeLineChartItemsIds,
  lineChartItems
}: Props) => {
  // eslint-disable-next-line
  const getMin = (data: { [key: string]: any }[], property: string): number =>
    data.reduce((min, object) => (object[property] < min ? object[property] : min), data[0][property]);
  // eslint-disable-next-line
  const getMax = (data: { [key: string]: any }[], property: string): number =>
    data.reduce((max, object) => (object[property] > max ? object[property] : max), data[0][property]);

  //const fishTick = Math.round(Math.ceil(getMax(stats, 'fish') / 1000) * 250);
  const fishTick = getMax(stats, 'fish') > 100 ?
    Math.round(Math.ceil(getMax(stats, 'fish') / 100) * 25) :
    Math.round(Math.ceil(getMax(stats, 'fish') / 10) * 2.5);
  const num_fishTick = Math.ceil((getMax(stats, 'fish') + fishTick) / fishTick);
  const fishTicks = Array.from({ length: num_fishTick }, (v, k) => k * fishTick);
  const maxWelfareIncidentsSum = getMax(stats, 'welfareIncidentsSum');
  const maxLiceSum = getMax(stats, 'liceSum');
  const maxMetricValue = Math.max(maxWelfareIncidentsSum, maxLiceSum);
  const welfareTick = Math.ceil(maxMetricValue * 10) / 40;
  const welfareTicks = Array.from({ length: 5 }, (v, k) => Math.round(k * welfareTick * 1000) / 1000);
  const theme: AugmentedTheme = useTheme();

  const getLegendWidth = () => {
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const custom1Up = useMediaQuery('(min-width:1050px)');
    const custom2Up = useMediaQuery('(min-width:1150px)');
    let legendWidth = 140;
    if (mdUp) {
      legendWidth = 190;
    }
    if (custom1Up) {
      legendWidth = 300;
    }
    if (custom2Up) {
      legendWidth = 350;
    }
    if (lgUp) {
      legendWidth = 400;
    }
    return legendWidth;
  };
  return (
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart width={600} height={350} data={stats}>
        <defs>
          <linearGradient id="colorWelfare" x1="0" y1="0" x2="0" y2="1">
            <stop offset="25%" stopColor={theme.colors.seaGreen} stopOpacity={0.625} />
            <stop offset="95%" stopColor={theme.colors.oxfordBlue} stopOpacity={0.625} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke={theme.colors.oxfordBlue01} />
        <XAxis
          dataKey="date"
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          stroke={theme.colors.oxfordBlue04}
          tickFormatter={(item) => {
            return statsInterval == StatsIntervalStatus.weekly
              ? `${Localization.getInst().localizedString(LocalizationKey.week)} ${getHumanWeek(item)}`
              : getHumanShortDate(item);
          }}
        />
        <YAxis
          yAxisId="left"
          label={{
            value: Localization.getInst().localizedString(LocalizationKey.scannedFishCount),
            angle: -90,
            position: 'insideBottomLeft',
            offset: 10,
            style: theme.typography.h6
          }}
          tickLine={false}
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          ticks={fishTicks}
          type="number"
          domain={[0, 'dataMax']}
          stroke={theme.colors.oxfordBlue04}
        />
        {/*
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: Localization.getInst().localizedString(LocalizationKey.itemsFoundPerFish),
            angle: -90,
            position: 'insideRight',
            offset: 10,
            style: theme.typography.h6
          }}
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          stroke={theme.colors.transparent}
          ticks={welfareTicks}
          domain={[0, 'dataMax']}
          tickLine={false}
        />
        */}
        <Bar
          name="Scanned fish"
          yAxisId="left"
          dataKey="fish"
          legendType="none"
          fill={theme.colors.oxfordBlue02}
          stroke={0}
          strokeWidth={0}>
          <LabelList dataKey="fish" position="top" />
        </Bar>
        {/*
        <Area
          name="Welfare"
          yAxisId="right"
          type="monotone"
          dataKey="welfareIncidentsSum"
          legendType="rect"
          fill="url(#colorWelfare)"
          stroke="url(#colorWelfare)"
          strokeWidth={0}
        />
        {lineChartItems.map((item) => (
          <Line
            key={item.id}
            name={item.name}
            hide={activeLineChartItemsIds.indexOf(item.id) == -1}
            yAxisId="right"
            type="monotone"
            dot={item.dotColor ? { fill: item.dotColor, strokeWidth: 0, r: 2 } : false}
            dataKey={item.id}
            legendType={activeLineChartItemsIds.indexOf(item.id) == -1 ? 'none' : item.legendType}
            stroke={item.color}
            strokeWidth={1.25}
            label={
              item.minMaxLabelProps ? (
                <WelfareGraphLabelMinMax
                  max={getMax(stats, item.id)}
                  min={getMin(stats, item.id)}
                  {...item.minMaxLabelProps}
                />
              ) : (
                false
              )
            }
          />
        ))}
        <Tooltip
          position={{ x: 400, y: 0 }}
          itemStyle={{ ...theme.typography.h6 }}
          labelStyle={{ ...theme.typography.h3, marginBottom: '10px' }}
          formatter={(value) => (typeof value === 'number' ? Math.round(value * 100) / 100 : value)}
          labelFormatter={(item) => {
            return statsInterval == StatsIntervalStatus.weekly
              ? `${Localization.getInst().localizedString(LocalizationKey.week)} ${getHumanWeek(item)}`
              : getHumanShortDate(item);
          }}
        />
        */}
        <Legend
          align="left"
          verticalAlign="top"
          iconSize={16}
          height={55}
          width={getLegendWidth()}
          wrapperStyle={{ ...theme.typography.h6 }}
        />
        <CartesianGrid stroke={theme.colors.whiteSmoke} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
