import React, { FunctionComponent, useState, useEffect } from 'react';
import Carousel, { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Theme as AugmentedTheme, Box, useMediaQuery, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { CarouselButton } from '../../CarouselButton/CarouselButton';
import { Carousel as CarouselData, FishImage } from '../../../types/models';
import { Localization } from '../../../localization/Localization';
import { LocalizationKey } from '../../../localization/LocalizationKey';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { TodaysCatchSectionFullScreenCarousel } from '../FullScreenCarousel/FullScreenCarousel';
import { getHumanTime } from '../../../logic/dateUtil';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  carouselWrapper: {
    width: '100%',
    margin: 'auto'
  },
  slideContainer: {
    position: 'relative',
    width: '240px',
    height: '130px'
  },
  imageContainer: {
    width: 'inherit',
    height: 'inherit',
    backgroundColor: theme.colors.iron,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxHeight: '130px',
    maxWidth: '240px'
  },
  imageDateContainer: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    padding: theme.remSpacing(1),
    backgroundColor: theme.colors.white,
    borderRadius: theme.shape.borderRadius
  }
}));

interface Props {
  carouselData: CarouselData;
  handleCarouselEnding: Function;
}

export const TodaysCatchSectionCarousel: FunctionComponent<Props> = ({ carouselData, handleCarouselEnding }: Props) => {
  const theme: AugmentedTheme = useTheme();
  const styles = useStyles();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  let visibleSlidesNumber = 1;
  const [images, setImages] = useState<FishImage[]>(carouselData.images);
  const [currentSlideIndex, setcurrentSlideIndex] = useState<number>(0);
  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false);

  const handleOpenFullScreen = (currentSlide: number) => {
    setcurrentSlideIndex(currentSlide);
    setOpenFullScreen(true);
  };

  useEffect(() => {
    setImages(carouselData.images);
  }, [carouselData]);

  const isSliderEnding = (prevSlide: number) => {
    const slidesCount = images.length;
    const nearEndMultiplier = 3;
    const sliderNearTheEnd = prevSlide > slidesCount - visibleSlidesNumber * nearEndMultiplier;
    if (sliderNearTheEnd) {
      handleCarouselEnding();
    }
  };

  const getVisibleSlidesNumber = () => {
    if (mdUp) {
      visibleSlidesNumber = 2;
    }
    if (lgUp) {
      visibleSlidesNumber = 3;
    }
    return visibleSlidesNumber;
  };
  const prevSlideButton = !lgUp
    ? null
    : ({ previousSlide, currentSlide, slideCount, slidesToShow }: CarouselSlideRenderControlProps) => (
        <CarouselButton
          label={Localization.getInst().localizedString(LocalizationKey.carouselPrevious)}
          icon={<ChevronLeft fontSize="large" />}
          direction="left"
          onButtonClick={previousSlide}
          currentSlide={currentSlide}
          slideCount={slideCount}
          slidesToShow={slidesToShow}
        ></CarouselButton>
      );

  return (
    <Box className={styles.carouselWrapper}>
      <Carousel
        transitionMode={'scroll'}
        cellSpacing={20}
        slideIndex={currentSlideIndex}
        slidesToShow={getVisibleSlidesNumber()}
        slidesToScroll={getVisibleSlidesNumber()}
        afterSlide={(prevSlide) => {
          isSliderEnding(prevSlide);
          false === openFullScreen && setcurrentSlideIndex(prevSlide);
        }}
        slideWidth="240px"
        renderBottomCenterControls={null}
        renderCenterLeftControls={prevSlideButton}
        renderCenterRightControls={({ nextSlide, currentSlide, slideCount, slidesToShow }) => (
          <CarouselButton
            label={Localization.getInst().localizedString(LocalizationKey.carouselNext)}
            icon={<ChevronRight fontSize="large" />}
            direction="right"
            onButtonClick={nextSlide}
            currentSlide={currentSlide}
            slideCount={slideCount}
            slidesToShow={slidesToShow}
          ></CarouselButton>
        )}
      >
        {images.map((image, index) => (
          <Box key={image.id} className={styles.slideContainer} onClick={() => handleOpenFullScreen(index)}>
            <Box className={styles.imageContainer}>
              <img className={styles.image} src={image.url} />
            </Box>
            <Box className={styles.imageDateContainer}>
              <Typography variant="h5">{getHumanTime(image.date.toDate())}</Typography>
            </Box>
          </Box>
        ))}
      </Carousel>

      <ModalWindow open={openFullScreen} setOpen={setOpenFullScreen} fullScreen={true}>
        <TodaysCatchSectionFullScreenCarousel
          carouselImages={images}
          initialSlideIndex={currentSlideIndex}
          handleAfterSlide={setcurrentSlideIndex}
        />
      </ModalWindow>
    </Box>
  );
};
