import React, { FunctionComponent, useEffect, useState, Fragment } from "react";
import {
  Locality,
  Cage,
  StatsRow,
  FishImage,
  StatsIntervalStatus,
  Sensor,
} from "../../types/models";
import {
  LocalityFirebase,
  FishImageFirebase,
  FishImageStatusFirebase,
  SensorFirebase,
} from "../../types/modelsFirebase";
import { InfoPanel } from "../../ui-components/InfoPanel/InfoPanel";
import { FirebaseClient } from "../Firebase/Client";
import { withFirebase } from "../Firebase/withFirebase";
import { MainPanel } from "../../ui-components/MainPanel/MainPanel";
import { DayRangeAsString } from "../../ui-components/DayRangeSelector/DayRangeSelectorInterface";
import {
  calculateLocalityStats,
  calculateCageStats,
} from "../../logic/statsUtils";
import { Timestamp } from "@google-cloud/firestore";
import { LocalizationKey } from "../../localization/LocalizationKey";
import { Localization } from "../../localization/Localization";
import { Fade } from "@material-ui/core";
import { ProgressBar } from "../../ui-components/ProgressBar/ProgressBar";

export type TTabs = "welfare" | "biomass";

interface LocalityDetailsBaseProps {
  firebase: FirebaseClient;
  organizationId: string;
  search: { localityId: string };
}

const LocalityDetailsBase: FunctionComponent<LocalityDetailsBaseProps> = (
  props: LocalityDetailsBaseProps
) => {
  const [loading, setLoading] = useState(true);
  const [progressBar, setProgressBar] = useState(false);
  const [liftupResponse, setLiftupResponse] = useState<string>("");
  const [locality, setLocality] = useState<Locality | undefined>(undefined);
  const [stats, setStats] = useState<StatsRow[] | undefined>(undefined);
  const [localityStats, setlocalityStats] = useState<StatsRow[] | undefined>(
    undefined
  );
  const [cages, setCages] = useState<Cage[]>([]);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [selectedCage, setSelectedCage] = useState<Cage | undefined>(undefined);
  const [queryDates, setQueryDates] = useState<DayRangeAsString | null>(null);
  const [statsInterval, setStatsInterval] = useState<StatsIntervalStatus>(
    StatsIntervalStatus.daily
  );
  const [tab, setTab] = useState<TTabs>("welfare");

  const firebaseInit = async () => {
    if (props.firebase) {
      const localitySnapshot = await props.firebase.getLocalitySnapshot(
        props.organizationId,
        props.search.localityId
      );
      const localityFirebase = localitySnapshot.data() as LocalityFirebase;
      const locality = new Locality(localityFirebase);
      const sortedCages = locality.cages.sort(
        (a, b) => a.cageIndex - b.cageIndex
      );
      const sensorsSnapshot = await props.firebase.getLocalitySensorsSnapshot(
        props.search.localityId
      );
      const sensors = sensorsSnapshot.docs.map((doc) => {
        const data = doc.data() as SensorFirebase;
        const id = doc.id;
        return { id, ...data };
      });
      setLocality(locality);
      setCages(sortedCages);
      setSensors(sensors);
      setLoading(false);
    }
  };

  const getFishImagesData = async (
    untilTimestamp: Timestamp,
    status?: string,
    cageId?: string
  ) => {
    const fishImagesSnapshot = await props.firebase.getFishImagesSnapshot(
      props.organizationId,
      props.search.localityId,
      untilTimestamp,
      status,
      cageId
    );

    return fishImagesSnapshot.docs.map((doc) => {
      const data = doc.data() as FishImageFirebase;
      const id = doc.id;
      return { id, ...data };
    });
  };

  const selectCageForLiftup = async (
    organizationId: string,
    localityId: string,
    selectedCage: undefined | number
  ) => {
    const setSelectedCage = props.firebase.setSelectedCage();
    const response = await (
      await setSelectedCage({ organizationId, localityId, selectedCage })
    ).data;
    if (response && response["result"]) setLiftupResponse(response["result"]);
    else if (response && response["error"])
      setLiftupResponse(response["error"]);
    else setLiftupResponse("");
  };

  const fetchFishImages = async (
    cageId?: string,
    statuses?: FishImageStatusFirebase[],
    untilTimestamp?: Timestamp
  ): Promise<FishImage[]> => {
    const _untilTimestamp = untilTimestamp
      ? untilTimestamp
      : props.firebase.getCurrentTimestamp();
    let result: FishImage[] = [];
    if (statuses) {
      for (status of statuses) {
        const fishImagesData = await getFishImagesData(
          _untilTimestamp,
          cageId,
          status
        );
        result = result.concat(fishImagesData);
      }
      result.sort((a, b) => b.date.seconds - a.date.seconds);
    } else {
      result = await getFishImagesData(_untilTimestamp, cageId);
    }
    return result;
  };

  const fetchStats = async () => {
    if (!queryDates || !queryDates.from || !queryDates.to) {
      return;
    }
    const statsSnapshot = await props.firebase.getLocalityStatsSnapshot(
      props.organizationId,
      props.search.localityId,
      queryDates.from,
      queryDates.to
    );
    const data = statsSnapshot.docs.map((doc) => {
      return doc.data() as StatsRow;
    });
    return data;
  };

  const handleStatsQuery = async () => {
    const data = await fetchStats();
    if (data) {
      setStats(data);
      setlocalityStats(calculateLocalityStats(data, statsInterval));
    }
  };

  const handleStatsInterval = (statsInterval: StatsIntervalStatus) => {
    if (stats) {
      setStatsInterval(statsInterval);
      setlocalityStats(calculateLocalityStats(stats, statsInterval));
    }
  };

  const getCurrentStats = () => {
    if (undefined != selectedCage && stats) {
      let currentCageStats = stats.filter(
        (statsRow) => statsRow.cageId == selectedCage.cageId
      );
      if (statsInterval === StatsIntervalStatus.weekly) {
        currentCageStats = calculateCageStats(currentCageStats);
      }
      return currentCageStats;
    }
    return localityStats;
  };

  const getLocalityImage = (firebase: FirebaseClient, locality: Locality) => {
    if (locality.locationImagePath != undefined) {
      firebase
        .getLocalityImageUrl(locality.locationImagePath)
        .then((url) => {
          locality.locationImageUrl = url;
          setLocality(locality);
        })
        .catch(() => {
          locality.locationImageUrl = null;
          setLocality(locality);
        });
    } else {
      locality.locationImageUrl = null;
      setLocality(locality);
    }
  };

  useEffect(() => {
    setLoading(true);
    setSelectedCage(undefined);
    firebaseInit();
  }, [props.search]);

  useEffect(() => {
    if (locality != undefined) {
      getLocalityImage(props.firebase, locality);
    }
  }, [locality]);

  useEffect(() => {
    handleStatsQuery();
  }, [props.search, queryDates]);

  return (
    <Fragment>
      <Fade
        in={loading}
        onEnter={() => setProgressBar(true)}
        onExited={() => setProgressBar(false)}
        unmountOnExit
      >
        <ProgressBar />
      </Fade>
      {!loading && !progressBar && locality === undefined && (
        <h1>
          {Localization.getInst().localizedString(
            LocalizationKey.noSuchLocality
          )}
        </h1>
      )}
      {!loading && !progressBar && locality !== undefined && (
        <Fragment>
          <InfoPanel
            locality={locality}
            cages={cages}
            sensors={sensors}
            selectedCage={selectedCage}
            setSelectedCage={setSelectedCage}
            selectCageForLiftup={() =>
              selectCageForLiftup(
                props.organizationId,
                locality.localityId,
                selectedCage?.cageIndex
              )
            }
            liftupResponse={liftupResponse}
            tab={tab}
            statsInterval={statsInterval}
          />
          <MainPanel
            firebaseInit={!loading}
            stats={getCurrentStats()}
            cages={cages}
            selectedCage={selectedCage}
            statsInterval={statsInterval}
            queryDates={queryDates}
            setSelectedCage={setSelectedCage}
            fetchFishImages={fetchFishImages}
            setQueryDates={setQueryDates}
            setStatsInterval={handleStatsInterval}
            localityName={locality.name}
            displayBiomassGraph={!!locality.displayBiomassGraph}
            tab={tab}
            onTabChange={setTab}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export const LocalityDetails = withFirebase(LocalityDetailsBase);
