import { StatsRow, StatsIntervalStatus } from '../types/models';
import { METRIC, METRICS } from '../constants/metrics';
import moment from 'moment';

type statsSumByDateRow = StatsRow & { cageNumber: number; week: number; year: number };

export const calculateLocalityStats = (statsRows: StatsRow[], statsInterval: StatsIntervalStatus): StatsRow[] | [] => {
  if (statsRows.length == 0) {
    return [];
  }

  const initialMetrics = METRICS.reduce((metrics, metric) => {
    metrics[metric.id] = 0;
    return metrics;
  }, ({} as unknown) as { [key: string]: number });

  let localityStatsSumByDate: statsSumByDateRow[] = Object.values(
    statsRows.reduce((aggregatedRows, row) => {
      aggregatedRows[row.date] = aggregatedRows[row.date] || {
        date: row.date,
        week: moment(row.date).isoWeek(),
        year: moment(row.date).year(),
        cageNumber: 0,
        ...initialMetrics
      };
      METRICS.forEach((metric: METRIC) => {
        row[metric.id]
          ? metric.customAggregateRule
            ? (aggregatedRows[row.date][metric.id] = metric.customAggregateRule(
                aggregatedRows[row.date][metric.id],
                row[metric.id]
              ))
            : (aggregatedRows[row.date][metric.id] += +row[metric.id])
          : false;
      });
      aggregatedRows[row.date].cageNumber = ++aggregatedRows[row.date].cageNumber;
      return aggregatedRows;
    }, ({} as unknown) as { [key: string]: statsSumByDateRow })
  );

  if (statsInterval === StatsIntervalStatus.weekly) {
    localityStatsSumByDate = Object.values(
      localityStatsSumByDate.reduce((aggregatedRows, row) => {
        const date = moment().year(row.year).isoWeek(row.week).isoWeekday(1).format('YYYY-MM-DD');
        const rowLabel = `${row.year}-${row.week}`;
        aggregatedRows[rowLabel] = aggregatedRows[rowLabel] || {
          week: row.week,
          year: row.year,
          date: date,
          cageNumber: 0,
          ...initialMetrics
        };
        METRICS.forEach((metric: METRIC) => {
          if (row[metric.id]) {
            metric.customAggregateRule
              ? (aggregatedRows[rowLabel][metric.id] = metric.customAggregateRule(
                  aggregatedRows[rowLabel][metric.id],
                  row[metric.id]
                ))
              : (aggregatedRows[rowLabel][metric.id] += +row[metric.id]);
          }
        });
        aggregatedRows[rowLabel].cageNumber = aggregatedRows[rowLabel].cageNumber + row.cageNumber;
        return aggregatedRows;
      }, ({} as unknown) as { [key: string]: statsSumByDateRow })
    );
  }

  const localityStats: StatsRow[] = localityStatsSumByDate.reduce(
    (calculatedStatsRows: StatsRow[], statsRow: statsSumByDateRow) => {
      const aggregatedStats: { [key: string]: number } = {};
      METRICS.forEach((metric: METRIC) => {
        switch (metric.aggregateRule) {
          case 'avg':
            aggregatedStats[metric.id] = Math.round((statsRow[metric.id] / statsRow.cageNumber) * 100) / 100;
            break;
          case 'sum':
          case 'custom':
            aggregatedStats[metric.id] = statsRow[metric.id];
            break;
        }
      });

      calculatedStatsRows.push(({
        date: statsRow.date,
        week: statsRow.week,
        year: statsRow.year,
        ...aggregatedStats
      } as unknown) as StatsRow);

      return calculatedStatsRows;
    },
    [] as StatsRow[]
  );
  return localityStats;
};

export const calculateCageStats = (statsRows: StatsRow[]): StatsRow[] | [] => {
  if (statsRows.length == 0) {
    return [];
  }
  const initialMetrics = METRICS.reduce((metrics, metric) => {
    metrics[metric.id] = 0;
    return metrics;
  }, ({} as unknown) as { [key: string]: number });

  const aggregatedStats = Object.values(
    statsRows.reduce((aggregatedRows, row) => {
      const week = row.week ? row.week : moment(row.date).isoWeek();
      const year = row.year ? row.year : moment(row.date).year();
      const date = moment().year(year).isoWeek(week).isoWeekday(1).format('YYYY-MM-DD');
      const rowLabel = `${year}-${week}`;
      aggregatedRows[rowLabel] = aggregatedRows[rowLabel] || {
        week: week,
        year: year,
        date: date,
        cageNumber: 0,
        ...initialMetrics
      };
      METRICS.forEach((metric: METRIC) => {
        if (row[metric.id]) {
          metric.customAggregateRule
            ? (aggregatedRows[rowLabel][metric.id] = metric.customAggregateRule(
                aggregatedRows[rowLabel][metric.id],
                row[metric.id]
              ))
            : (aggregatedRows[rowLabel][metric.id] += +row[metric.id]);
        }
      });
      aggregatedRows[rowLabel].cageNumber = ++aggregatedRows[rowLabel].cageNumber;
      return aggregatedRows;
    }, ({} as unknown) as { [key: string]: statsSumByDateRow })
  );

  const cageStats: StatsRow[] = aggregatedStats.reduce(
    (calculatedStatsRows: StatsRow[], statsRow: statsSumByDateRow) => {
      const aggregatedStats: { [key: string]: number } = {};
      METRICS.forEach((metric: METRIC) => {
        switch (metric.aggregateRule) {
          case 'avg':
            aggregatedStats[metric.id] = Math.round((statsRow[metric.id] / statsRow.cageNumber) * 100) / 100;
            break;
          case 'sum':
          case 'custom':
            aggregatedStats[metric.id] = statsRow[metric.id];
            break;
        }
      });

      for (const propName in aggregatedStats) {
        if (aggregatedStats[propName] === null || aggregatedStats[propName] === NaN) {
          delete aggregatedStats[propName];
        }
      }

      calculatedStatsRows.push(({
        date: statsRow.date,
        week: statsRow.week,
        year: statsRow.year,
        ...aggregatedStats
      } as unknown) as StatsRow);

      return calculatedStatsRows;
    },
    [] as StatsRow[]
  );
  return cageStats;
};
