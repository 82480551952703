import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
} from "recharts";
import { useTheme } from "@material-ui/styles";
import { Theme as AugmentedTheme } from "@material-ui/core";
import { StatsRow } from "../../types/models";
import { Localization } from "../../localization/Localization";
import { LocalizationKey } from "../../localization/LocalizationKey";
import { getHumanShortDate } from "../../logic/dateUtil";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    /* So you can display labels outside of chart area */
    "& .recharts-wrapper-show-outside-labels svg": {
      overflow: "inherit !important",
    },
  },
}));

interface Props {
  stats: StatsRow;
  height?: number;
}

export const WeightDistributionGraph: React.FunctionComponent<Props> = ({
  stats,
  height,
}: Props) => {
  const theme: AugmentedTheme = useTheme();
  const { weightFromLength, weight_distribution } = stats;
  const MAX_BUCKET_COUNT = 15;
  const bucketMin = (weight_distribution || []).reduce(
    (min, next, index) =>
      index > 0 ? (next.bucket < min ? next.bucket : min) : next.bucket,
    0
  );

  const bucketMax = (weight_distribution || []).reduce(
    (max, next) => (next.bucket > max ? next.bucket : max),
    0
  );
  const bucketCount = (bucketMax - bucketMin) / 10 + 1;
  const bucketAggregateCount = Math.ceil(bucketCount / MAX_BUCKET_COUNT);
  const adjustedBucketCount = Math.ceil(bucketCount / bucketAggregateCount);

  const weightDistributionAggregated = [
    ...Array(adjustedBucketCount).keys(),
  ].map((i) => {
    const bucket = i * 10 * bucketAggregateCount + bucketMin;
    return {
      bucket,
      count: (weight_distribution || [])
        .filter(
          (d) =>
            d.bucket >= bucket && d.bucket < bucket + 10 * bucketAggregateCount
        )
        .reduce((sum, d) => sum + d.count, 0),
    };
  });

  const styles = useStyles();

  const new_weight_distribution = weight_distribution.map((s) => ({
    bucket: s.bucket + 50,
    count: s.count,
  }));

  console.log("new", new_weight_distribution);

  return (
    <ResponsiveContainer
      className={styles.container}
      width="90%"
      height={height || 274}
    >
      <ComposedChart
        data={new_weight_distribution.map((s) => ({
          bucket: s.bucket,
          count: s.count,
        }))}
        className="recharts-wrapper-show-outside-labels"
      >
        <defs>
          <linearGradient id="colorWelfare" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="25%"
              stopColor={theme.colors.seaGreen}
              stopOpacity={0.625}
            />
            <stop
              offset="95%"
              stopColor={theme.colors.oxfordBlue}
              stopOpacity={0.625}
            />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke={theme.colors.oxfordBlue01} />
        <XAxis
          dataKey="bucket"
          label={{
            value: Localization.getInst().localizedString(
              LocalizationKey.averageWeightLabel
            ),
            position: "right",
            style: theme.typography.h6,
          }}
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          stroke={theme.colors.oxfordBlue04}
        />
        <XAxis
          dataKey="bucket"
          xAxisId="xNumber"
          type="number"
          domain={[bucketMin, bucketMax]}
          hide={true}
        />
        <YAxis
          yAxisId="left"
          label={{
            value: Localization.getInst().localizedString(
              LocalizationKey.scannedFishCount
            ),
            angle: -90,
            position: "insideBottomLeft",
            offset: 10,
            style: theme.typography.h6,
          }}
          tickLine={false}
          tick={{ fill: theme.colors.oxfordBlue, ...theme.typography.h6 }}
          domain={[0, "dataMax"]}
          stroke={theme.colors.oxfordBlue04}
        />
        <Bar
          name={Localization.getInst().localizedString(
            LocalizationKey.scannedFishCountBar
          )}
          yAxisId="left"
          dataKey="count"
          legendType="none"
          fill={theme.colors.darkPurple}
          stroke={0}
          strokeWidth={0}
        />
        <ReferenceLine
          x={Math.round(weightFromLength)}
          xAxisId="xNumber"
          yAxisId="left"
          stroke="green"
        >
          <Label
            value={Localization.getInst().localizedString(
              LocalizationKey.weightDistributionAverage,
              `${Math.round(weightFromLength)}`
            )}
            position="top"
          />
        </ReferenceLine>
        <Tooltip
          itemStyle={{ ...theme.typography.h6 }}
          labelStyle={{ ...theme.typography.h3, marginBottom: "10px" }}
          formatter={(value) => [
            `${value} fish scanned`,
            getHumanShortDate(stats.date),
          ]}
          labelFormatter={(item) => {
            const itemInt = typeof item === "number" ? item : parseInt(item);
            return `${itemInt}g - ${itemInt + 10 * bucketAggregateCount}g`;
          }}
        />
        <CartesianGrid stroke={theme.colors.whiteSmoke} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
