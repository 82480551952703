import React from "react";
import { Typography, CardContent, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme as AugmentedTheme } from "@material-ui/core";
import { StatusBox } from "../../StatusBox/StatusBox";
import { Cage, StatsIntervalStatus, Sensor } from "../../../types/models";
import { Localization } from "../../../localization/Localization";
import { LocalizationKey } from "../../../localization/LocalizationKey";
import { TTabs } from "../../../components/LocalityDetails/LocalityDetails";
import { LiftupDialog } from "../../SelectLiftupCage/LiftupDialog";

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  wrapper: {
    height: "240px",
    background: theme.colors.white,
    padding: theme.remSpacing(5, 4, 0, 10),
  },
  fishWelfareTitle: {
    color: theme.colors.oxfordBlue06,
  },
  locationTitle: {
    paddingBottom: theme.remSpacing(7),
  },
}));

interface Props {
  cage: Cage;
  selectCageForLiftup: () => Promise<void>;
  liftupResponse: string;
  sensor?: Sensor;
  tab: TTabs;
  statsInterval: StatsIntervalStatus;
}

export const CagesGridDetails: React.FunctionComponent<Props> = ({
  cage,
  selectCageForLiftup,
  liftupResponse,
  sensor,
  tab,
  statsInterval,
}: Props) => {
  const styles = useStyles();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  return (
    <CardContent className={styles.wrapper}>
      <Typography variant="h3" className={styles.fishWelfareTitle} gutterBottom>
        {Localization.getInst().localizedString(
          tab === "welfare"
            ? statsInterval === StatsIntervalStatus.weekly
              ? LocalizationKey.localityDetailsWeeklyFishWelfareIn
              : LocalizationKey.localityDetailsFishWelfareIn
            : LocalizationKey.localityDetailsFishBiomassIn
        )}
      </Typography>
      <Typography variant="h3" className={styles.locationTitle}>
        {cage.name} {/* sensor ? `, (${sensor.id})` : '' */}
      </Typography>
      <StatusBox cage={cage} statsInterval={statsInterval} hideIcon />
      <Button variant="contained" onClick={() => setOpenDialog(true)}>
        <Typography variant="h3">
          {Localization.getInst().localizedString(
            LocalizationKey.cageForLiftupButton
          )}
        </Typography>
      </Button>
      <LiftupDialog
        open={openDialog}
        setOpen={setOpenDialog}
        text={Localization.getInst().localizedString(
          LocalizationKey.cageForLiftupText,
          cage.name
        )}
        onClick={selectCageForLiftup}
      ></LiftupDialog>
    </CardContent>
  );
};
