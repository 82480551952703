import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { AuthUser } from '../types/modelsFirebase';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme } from '@material-ui/core';
import { SessionContext } from '../components/Session/Context';
import { withAuthorization } from '../components/Session/withAuthorization';
import { withEmailVerification } from '../components/Session/withEmailVerification';
import { LocalityDetails } from '../components/LocalityDetails/LocalityDetails';
import { withLocation } from '../components/withLocation';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: theme.remSpacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6)
    }
  }
}));

const LocalityDetailsPageBase = (props: { search: { localityId: string } }) => {
  const styles = useStyles();

  return (
    <Fragment>
      <SessionContext.Consumer>
        {(authUser) =>
          authUser &&
          authUser.organizationId && (
            <div className={styles.container}>
              <LocalityDetails search={props.search} organizationId={authUser.organizationId} />
            </div>
          )
        }
      </SessionContext.Consumer>
    </Fragment>
  );
};

const condition = (authUser: AuthUser) => !!authUser;

const LocalityDetailsPage = compose<{ search: { localityId: string } }, {}>(
  withEmailVerification,
  withAuthorization(condition),
  withLocation
)(LocalityDetailsPageBase);

export default () => <LocalityDetailsPage />;
