import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography, Box, Button, Fade } from '@material-ui/core';
import { Cage } from '../../types/models';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  navigationWrapper: {
    display: 'flex',
    height: '65px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  prevButtonWrapper: {
    flex: '1',
    display: 'flex'
  },
  nextButtonWrapper: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  allCagesButton: {
    flex: '1',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: '43px',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    textTransform: 'none'
  },
  allCagesButtonLabel: {
    color: theme.colors.black,
    textDecoration: 'underline'
  }
}));

interface Props {
  cages: Cage[];
  selectedCage: Cage | undefined;
  setSelectedCage: (value: React.SetStateAction<Cage | undefined>) => void;
}

export const CagesNavigation: FunctionComponent<Props> = ({ cages, selectedCage, setSelectedCage }: Props) => {
  const selectedCageIndex = cages.findIndex((cage) =>
    selectedCage ? cage.cageIndex === selectedCage.cageIndex : undefined
  );
  const prevButtonCageIndex = selectedCageIndex - 1;
  const prevButtonCage = cages[prevButtonCageIndex];
  const prevCageIndexString = prevButtonCage ? `${prevButtonCageIndex + 1}` : `1`;
  const prevButtonLabel = Localization.getInst().localizedString(
    LocalizationKey.cagesNavigationSeeCage,
    prevCageIndexString
  );
  const nextButtonCageIndex = selectedCageIndex + 1;
  const nextButtonCage = cages[nextButtonCageIndex];
  const nextCageIndexString = nextButtonCage ? `${nextButtonCageIndex + 1}` : `${nextButtonCageIndex}`;
  const nextButtonLabel = Localization.getInst().localizedString(
    LocalizationKey.cagesNavigationSeeCage,
    nextCageIndexString
  );
  const styles = useStyles();
  return (
    <Box className={styles.navigationWrapper}>
      <Box className={styles.prevButtonWrapper}>
        <Fade
          in={undefined !== prevButtonCage}
          timeout={{
            enter: 1200,
            exit: 300
          }}
        >
          <Box>
            <ButtonWithIcon
              label={prevButtonLabel}
              icon={<ChevronLeft fontSize="large" />}
              direction="left"
              background="transparent"
              onButtonClick={() => setSelectedCage(prevButtonCage)}
            />
          </Box>
        </Fade>
      </Box>
      <Button classes={{ root: styles.allCagesButton }} variant="contained">
        <Typography variant="h3" className={styles.allCagesButtonLabel} onClick={() => setSelectedCage(undefined)}>
          {Localization.getInst().localizedString(LocalizationKey.cagesNavigationCompareAllCages)}
        </Typography>
      </Button>
      <Box className={styles.nextButtonWrapper}>
        <Fade
          in={undefined !== nextButtonCage}
          timeout={{
            enter: 1200,
            exit: 300
          }}
        >
          <Box>
            <ButtonWithIcon
              label={nextButtonLabel}
              icon={<ChevronRight fontSize="large" />}
              background="transparent"
              direction="right"
              onButtonClick={() => setSelectedCage(nextButtonCage)}
            />
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};
