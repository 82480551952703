/* eslint-disable */
import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface StylesProps {
  fullScreen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(4)
    },
    contentWrapper: {
      width: (props: StylesProps) => (props.fullScreen ? '100%' : 'inherit'),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: 'unset',
      padding: theme.spacing(4)
    },
    aa: {
      width: '99%',
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'pointer'
    }
  })
);

interface Props {
  children: React.ReactNode;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  fullScreen: boolean;
}

export const ModalWindow: React.FunctionComponent<Props> = ({ children, open, setOpen, fullScreen }: Props) => {
  const classes = useStyles({ fullScreen });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        disableAutoFocus
      >
        <Fade in={open}>
          <div className={classes.contentWrapper}>
          <div
          className={`MuiTypography-root makeStyles-label-83 MuiTypography-h3 ${classes.aa}`}
          onClick={handleClose}>
            X
          </div>
          {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
