import { DayRangeType } from './DayRangeSelectorInterface';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';

export const dayRangeLocalizedName = (dayRangeType: DayRangeType): string => {
  switch (dayRangeType) {
    case 'Last week':
      return Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorLastWeek);
    case 'Last month':
      return Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorLastMonth);
    case 'Last 6 months':
      return Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorLast6Months);
    case 'Last year':
      return Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorLastYear);
    case 'Custom':
      return Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorCustom);
  }
};
