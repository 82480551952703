import React from 'react';
import { Cage, SummaryStatType } from '../../../types/models';
import { WelfareIcon, DeadFishIcon } from '../../WelfareIcon/WelfareIcon';
import { Typography, Fade, CardContent, CardActions, Card, Box, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { WelfareIconSize } from '../../WelfareIcon/WelfareIconProps';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Localization } from '../../../localization/Localization';
import { LocalizationKey } from '../../../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    width: '100px',
    padding: theme.remSpacing(2.5, 2.5, 5, 2.5),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    transition: 'background-color 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: (props: { active: boolean }) => (props.active ? theme.colors.white : 'unset'),
    boxShadow: (props: { active: boolean }) => (props.active ? theme.shadow.big : 'unset'),
    '&:hover': {
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadow.big
    }
  },
  card: {
    backgroundColor: 'unset',
    boxShadow: 'unset'
  },
  cardContent: {
    padding: 0
  },
  cardActions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    height: theme.remSpacing(9),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.colors.white
  },
  arrowButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    textAlign: 'center'
  },
  arrowButton: {
    padding: 'unset',
    color: theme.colors.oxfordBlue
  }
}));
interface Props {
  cage: Cage;
  active: boolean;
  expanded: boolean;
  onCageClick: Function;
  onArrowClick: Function;
}

export const CagesGridItem: React.FunctionComponent<Props> = ({
  cage,
  active,
  expanded,
  onCageClick,
  onArrowClick
}: Props) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const handleCageSelect = () => {
    onCageClick(cage.cageId);
  };
  const handleArrowClick = () => {
    onArrowClick(cage.cageId);
  };
  const styles = useStyles({ active });

  const fishStat = (cage && cage.summaryStats) ? cage.summaryStats.find((summaryStat) => {
      return summaryStat.type == SummaryStatType.fish;
  }) : undefined;
  const fishStatNum = (fishStat == undefined || fishStat.value == null) ?  0 : fishStat.value;

  return (
    <Card
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={styles.container}
      key={cage.cageId}
    >
      <CardContent className={styles.cardContent}>
        <div onClick={handleCageSelect}>
          <Typography variant="h6" gutterBottom>
            {cage.name}
          </Typography>
          <DeadFishIcon fishStatNum={fishStatNum} fishTrend={cage.fishTrend} size={WelfareIconSize.small} />
        </div>
      </CardContent>
      <Fade in={hovered && !active}>
        <CardActions className={styles.cardActions}>
          <Box margin={'0 auto'}>
            <Link
              component="button"
              display="block"
              align="center"
              variant="h5"
              color="textPrimary"
              underline="always"
              onClick={handleCageSelect}
            >
              {Localization.getInst().localizedString(LocalizationKey.cagesGridSeeMore)}
            </Link>
          </Box>
        </CardActions>
      </Fade>
      <Fade
        in={active && !expanded}
        timeout={{
          enter: 1200,
          exit: 300
        }}
      >
        <Box className={styles.arrowButtonWrapper}>
          <IconButton className={styles.arrowButton} onClick={handleArrowClick}>
            <ExpandMore />
          </IconButton>
        </Box>
      </Fade>
    </Card>
  );
};
