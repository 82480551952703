import React, { useState, ChangeEvent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Localization } from "../../localization/Localization";
import { LocalizationKey } from "../../localization/LocalizationKey";
import { Typography } from "@material-ui/core";

interface Props {
  open: boolean;
  setOpen: Function;
  text: string;
  onClick: () => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 400,
    },
  })
);

export const LiftupDialog = (props: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="liftup-dialog"
    >
      <DialogContent className={classes.content}>
        <Typography variant="h3">{props.text} </Typography>
      </DialogContent>
      <br></br>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="primary">
          {Localization.getInst().localizedString(LocalizationKey.cancelButton)}
        </Button>
        <Button
          onClick={() => {
            props.onClick();
            props.setOpen(false);
          }}
          color="primary"
          autoFocus
        >
          {Localization.getInst().localizedString(
            LocalizationKey.activateButton
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
