import React, { FunctionComponent } from 'react';
import Carousel, { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Theme as AugmentedTheme, Box, useMediaQuery, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { CarouselButton } from '../../CarouselButton/CarouselButton';
import { FishImage } from '../../../types/models';
import { Localization } from '../../../localization/Localization';
import { LocalizationKey } from '../../../localization/LocalizationKey';
import { getHumanDateTime } from '../../../logic/dateUtil';
import { GlassMagnifier } from 'react-image-magnifiers';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  carouselWrapper: {
    width: '98%',
    height: '95%',
    margin: 'auto'
  },
  slideContainer: {
    position: 'relative',
    margin: 'auto -10px', // hack to counter nuka-slider wrapper margin, it fix image having bigger left margin
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  imageContainer: {
    width: 'inherit',
    height: 'inherit',
    backgroundColor: theme.colors.iron,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: '50%',
    width: '70%'
    //maxHeight: '100%',
    //maxWidth: '100%'
  },
  imageDateContainer: {
    position: 'absolute',
    bottom: 5,
    padding: theme.remSpacing(1),
    backgroundColor: theme.colors.white,
    borderRadius: theme.shape.borderRadius
  }
}));

interface Props {
  carouselImages: FishImage[];
  initialSlideIndex: number;
  handleAfterSlide: Function;
}

export const TodaysCatchSectionFullScreenCarouselBase: FunctionComponent<Props> = ({
  carouselImages,
  initialSlideIndex,
  handleAfterSlide
}: Props) => {
  const theme: AugmentedTheme = useTheme();
  const styles = useStyles();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const visibleSlidesNumber = 1;

  return (
    <Box className={styles.carouselWrapper}>
      <Carousel
        transitionMode={'fade'}
        heightMode={'current'}
        cellSpacing={20}
        slideIndex={initialSlideIndex}
        slidesToShow={visibleSlidesNumber}
        afterSlide={(prevSlide) => handleAfterSlide(prevSlide)}
        renderBottomCenterControls={null}
        renderCenterLeftControls={({
          previousSlide,
          currentSlide,
          slideCount,
          slidesToShow
        }: CarouselSlideRenderControlProps) => (
          <CarouselButton
            label={lgUp ? Localization.getInst().localizedString(LocalizationKey.carouselPrevious) : undefined}
            icon={<ChevronLeft fontSize="large" />}
            direction="left"
            onButtonClick={previousSlide}
            currentSlide={currentSlide}
            slideCount={slideCount}
            slidesToShow={slidesToShow}
          ></CarouselButton>
        )}
        renderCenterRightControls={({ nextSlide, currentSlide, slideCount, slidesToShow }) => (
          <CarouselButton
            label={lgUp ? Localization.getInst().localizedString(LocalizationKey.carouselNext) : undefined}
            icon={<ChevronRight fontSize="large" />}
            direction="right"
            onButtonClick={nextSlide}
            currentSlide={currentSlide}
            slideCount={slideCount}
            slidesToShow={slidesToShow}
          ></CarouselButton>
        )}
      >
        {carouselImages.map((image) => (
          <Box key={image.id} className={styles.slideContainer}>
            <Box className={styles.imageContainer}>
              <GlassMagnifier imageSrc={image.url} className={styles.image} />
            </Box>
            <Box className={styles.imageDateContainer}>
              <Typography variant="h3">{getHumanDateTime(image.date.toDate())}</Typography>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

const carouselImagesIsEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.carouselImages === nextProps.carouselImages;

export const TodaysCatchSectionFullScreenCarousel = React.memo(
  TodaysCatchSectionFullScreenCarouselBase,
  carouselImagesIsEqual
);
