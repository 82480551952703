import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Theme as AugmentedTheme } from '@material-ui/core';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    textAlign: 'center'
  },
  message: {
    paddingLeft: theme.remSpacing(5)
  }
}));

interface Props {
  cageId: string | undefined;
}

export const NoDataMessageWithWarningIcon = (props: Props) => {
  const styles = useStyles();

  const locationDescription = props.cageId != undefined ? `cage ${props.cageId}` : 'this location';

  return (
    <div className={styles.flexContainer}>
      <div className={styles.icon}>
        <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3201 16.673C17.3201 17.3778 16.7144 17.9759 16.0007 17.9759C15.2523 17.9759 14.6814 17.3778 14.6814 16.673V8.8572C14.6814 8.15238 15.287 7.55425 16.0007 7.55425C16.7144 7.55425 17.3201 8.15238 17.3201 8.8572V16.673ZM16.0007 23.222C15.2523 23.222 14.6814 22.622 14.6814 21.9172C14.6814 21.2143 15.287 20.6161 16.0007 20.6161C16.7144 20.6161 17.3201 21.2143 17.3201 21.9172C17.3201 22.622 16.7144 23.222 16.0007 23.222ZM30.938 23.643L17.9257 1.39381C17.0713 -0.0843939 14.8955 -0.0843939 14.039 1.39381L1.06341 23.643C0.206985 25.1231 1.27752 26.9518 2.98844 26.9518H28.9763C30.7239 26.9518 31.7925 25.1231 30.938 23.643Z"
            fill="#2E3C4B"
          />
        </svg>
      </div>
      <div className={styles.message}>
        <Typography variant="h5" component="div">
          {Localization.getInst().localizedString(LocalizationKey.weCantGetDataFromLocation, locationDescription)}
        </Typography>
        <Typography variant="h6" component="div">
          {Localization.getInst().localizedString(LocalizationKey.makeSureTheLenseOfTheCameraIsClean)}
        </Typography>
      </div>
    </div>
  );
};
