import moment, { Moment } from 'moment';

declare module 'moment' {
  interface Moment {
    lastWeek(): Moment;
    lastMonth(): Moment;
    last6Months(): Moment;
    lastYear(): Moment;
  }
}

moment.fn.lastWeek = (): Moment => {
  return moment().subtract(1, 'week');
};

moment.fn.lastMonth = (): Moment => {
  return moment().subtract(1, 'month');
};

moment.fn.last6Months = (): Moment => {
  return moment().subtract(6, 'month');
};

moment.fn.lastYear = (): Moment => {
  return moment().subtract(1, 'year');
};
