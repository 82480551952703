import React, { Fragment } from 'react';
import { Button, Typography, Theme as AugmentedTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface StylesProps {
  direction: 'left' | 'right' | undefined;
  background: string | undefined;
  label: string | undefined;
}

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  button: {
    minHeight: '40px',
    minWidth: (props: StylesProps) => (props.label === undefined ? '48px' : '64px'),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: (props: StylesProps) => (undefined != props.background ? props.background : theme.colors.white),
    boxShadow: 'unset',
    textTransform: 'none',
    paddingLeft: (props: StylesProps) =>
      props.direction == 'left' || props.label === undefined ? theme.spacing(1) : theme.spacing(3),
    paddingRight: (props: StylesProps) =>
      props.direction == 'right' || props.label === undefined ? theme.spacing(1) : theme.spacing(3),
    borderRadius: (props: StylesProps) =>
      props.direction == 'left' ? '0 4px 4px 0' : props.direction == 'right' ? '4px 0 0 4px' : '4px'
  },
  label: {
    color: theme.colors.black,
    textDecoration: 'underline'
  }
}));

interface Props {
  label?: string | undefined;
  icon: JSX.Element;
  background?: string;
  direction?: 'left' | 'right';
  onButtonClick: () => void;
}

export const ButtonWithIcon: React.FunctionComponent<Props> = ({
  label,
  icon,
  background,
  direction,
  onButtonClick
}: Props) => {
  const styles = useStyles({ direction, background, label });
  return (
    <Button classes={{ root: styles.button }} variant="contained" onClick={onButtonClick}>
      <Fragment>
        {direction == 'left' && icon}
        {label && (
          <Typography variant="h3" component="span" className={styles.label}>
            {label}
          </Typography>
        )}
        {direction != 'left' && icon}
      </Fragment>
    </Button>
  );
};
