import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { Button, Popover, Typography } from '@material-ui/core';
import { makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DayRangeType, DayRangeInfo, DayRange, DayRangeAsString } from '../DayRangeSelector/DayRangeSelectorInterface';
import { DayRangeSelector } from '../DayRangeSelector/DayRangeSelector';
import { dayRangeLocalizedName } from '../DayRangeSelector/DayRangeLocalizationUtils';
import { StatsIntervalStatus } from '../../types/models';
import moment from 'moment';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  popover: {
    overflow: 'visible',
    '& .MuiPopover-paper': {
      overflow: 'visible'
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  dayRangeButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center'
  }
}));

interface Props {
  setQueryDates: (value: React.SetStateAction<DayRangeAsString | null>) => void;
  statsInterval: StatsIntervalStatus;
}

export const DayRangeButton: FunctionComponent<Props> = ({ statsInterval, setQueryDates }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedDayRangeType, setSelectedDayRangeType] = useState<DayRangeType>('Last week');
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange | null>(null);
  useEffect(() => {
    if (selectedDayRange && selectedDayRange.from && selectedDayRange.to) {
      const from = selectedDayRange.from.clone().format('YYYY-MM-DD');
      const to = selectedDayRange.to.clone().format('YYYY-MM-DD');
      setQueryDates({ from, to });
    }
  }, [selectedDayRange]);

  useEffect(() => {
    if (statsInterval === StatsIntervalStatus.weekly) {
      setSelectedDayRangeType('Custom');
    }
  }, [statsInterval]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDayRangeSelect = (dayRangeInfo: DayRangeInfo) => {
    setSelectedDayRangeType(dayRangeInfo.type);
    setSelectedDayRange(dayRangeInfo.range);
    console.log(dayRangeInfo)
  };

  const weeklyIntervalInitialDayRange = (): DayRange => {
    const todayWeek = moment().isoWeek();

    return {
      //from: moment().lastMonth(),
      from: moment().subtract(6, 'months'),
      to: moment()
      //to: moment()
      //  .isoWeek(todayWeek - 1)
      //  .isoWeekday(7)
    };
  };

  const DailyIntervalInitialDayRange = (): DayRange => {
    return {
      from: moment().lastWeek(),
      to: moment()
    };
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Fragment>
      <Button className={classes.dayRangeButton} onClick={handleClick}>
        <Typography variant="h3" component="span">
          {dayRangeLocalizedName(selectedDayRangeType)}
        </Typography>
        <ExpandMoreIcon />
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {statsInterval === StatsIntervalStatus.daily && (
          <div id="daily">
            <DayRangeSelector
              initialDayRangeType={selectedDayRangeType}
              // customDayRange={selectedDayRangeType == 'Custom' ? selectedDayRange : null}
              customDayRange={DailyIntervalInitialDayRange()}
              onDayRangeSelect={onDayRangeSelect}
              statsInterval={statsInterval}
            />
          </div>
        )}
        {statsInterval === StatsIntervalStatus.weekly && selectedDayRangeType === 'Custom' && (
          <div id="weekly">
            <DayRangeSelector
              initialDayRangeType={selectedDayRangeType}
              customDayRange={weeklyIntervalInitialDayRange()}
              onDayRangeSelect={onDayRangeSelect}
              statsInterval={statsInterval}
            />
          </div>
        )}
      </Popover>
    </Fragment>
  );
};
