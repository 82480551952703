import { LegendType } from 'recharts';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';
import { WeightDistributionRow } from '../types/models';

export interface METRIC {
  id: string;
  name: string;
  selectorProps?: MetricSelectorProps;
  lineChartProps?: MetricLineChartProps;
  aggregateRule: string;
  customAggregateRule?: (acc: any, next: any) => any;
}

export interface MetricSelectorProps {
  index: number;
  active: boolean;
}

export interface MetricLineChartProps {
  color: string;
  legendType: LegendType;
  dotColor?: string;
  minMaxLabelProps?: { minColor: string; maxColor: string };
}

// this is config for daily stats
// 1. "id" corresponds to firestore metric name
// 1. "name" string used for display
// 2. "selectorProps.index" is used on stats selector so we know in what order display items,
// no display index means stats should not be displayed on selector
// 3. "selectorProps.active" mean if by default metric is selected on selector
// 4. "lineChartProps" metrics that are dynamic, displayed as lines and could be activated with selector.
// 5. "aggregateRule" is used for whole locality view, when no cage selected.
// We store stats per cage, so we have to aggregate for locality
export const METRICS: METRIC[] = [
  { id: 'fish', name: Localization.getInst().localizedString(LocalizationKey.scannedFishCount), aggregateRule: 'sum' },
//   {
//     id: 'movable',
//     name: Localization.getInst().localizedString(LocalizationKey.movable),
//     selectorProps: { index: 0, active: true },
//     lineChartProps: { color: '#f032e6', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'stationary',
//     name: Localization.getInst().localizedString(LocalizationKey.stationary),
//     selectorProps: { index: 1, active: true },
//     lineChartProps: { color: '#ffe119', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'grownUpFemale',
//     name: Localization.getInst().localizedString(LocalizationKey.grownUpFemale),
//     selectorProps: { index: 2, active: true },
//     lineChartProps: {
//       color: '#000000',
//       legendType: 'line',
//       dotColor: '#000000',
//       minMaxLabelProps: { minColor: '#000000', maxColor: '#000000' }
//     },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'eyeDamage',
//     name: Localization.getInst().localizedString(LocalizationKey.eyeDamage),
//     selectorProps: { index: 3, active: false },
//     lineChartProps: { color: '#4363d8', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'snoutDamage',
//     name: Localization.getInst().localizedString(LocalizationKey.snoutDamage),
//     selectorProps: { index: 4, active: false },
//     lineChartProps: { color: '#f58231', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'skinScratches',
//     name: Localization.getInst().localizedString(LocalizationKey.skinScratches),
//     selectorProps: { index: 5, active: false },
//     lineChartProps: { color: '#42d4f4', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'wound',
//     name: Localization.getInst().localizedString(LocalizationKey.wound),
//     selectorProps: { index: 6, active: false },
//     lineChartProps: { color: '#808000', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'skinDamage',
//     name: Localization.getInst().localizedString(LocalizationKey.skinDamage),
//     selectorProps: { index: 7, active: false },
//     lineChartProps: { color: '#fabebe', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'labridae',
//     name: Localization.getInst().localizedString(LocalizationKey.labridae),
//     selectorProps: { index: 8, active: false },
//     lineChartProps: { color: '#469990', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'gillDamage',
//     name: Localization.getInst().localizedString(LocalizationKey.gillDamage),
//     selectorProps: { index: 9, active: false },
//     lineChartProps: { color: '#e6beff', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'tailDamage',
//     name: Localization.getInst().localizedString(LocalizationKey.tailDamage),
//     selectorProps: { index: 10, active: false },
//     lineChartProps: { color: '#9A6324', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'deformity',
//     name: Localization.getInst().localizedString(LocalizationKey.deformity),
//     selectorProps: { index: 11, active: false },
//     lineChartProps: { color: '#800000', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'thinFish',
//     name: Localization.getInst().localizedString(LocalizationKey.thinFish),
//     selectorProps: { index: 12, active: false },
//     lineChartProps: { color: '#000075', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'manualMovable',
//     name: Localization.getInst().localizedString(LocalizationKey.manualMovable),
//     selectorProps: { index: 13, active: true },
//     lineChartProps: { color: '#c432f0', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'manualStationary',
//     name: Localization.getInst().localizedString(LocalizationKey.manualStationary),
//     selectorProps: { index: 14, active: false },
//     lineChartProps: { color: '#ffba19', legendType: 'plainline' },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'manualGrownUpFemale',
//     name: Localization.getInst().localizedString(LocalizationKey.manualGrownUpFemale),
//     selectorProps: { index: 15, active: false },
//     lineChartProps: {
//       color: '#736666',
//       legendType: 'line',
//       dotColor: '#736666',
//       minMaxLabelProps: { minColor: '#736666', maxColor: '#736666' }
//     },
//     aggregateRule: 'avg'
//   },
//   {
//     id: 'welfareIncidentsSum',
//     name: Localization.getInst().localizedString(LocalizationKey.welfareIncidentsSum),
//     aggregateRule: 'avg'
//   },
//   { id: 'liceSum', name: 'Lice', aggregateRule: 'avg' },
  {
    id: 'weight',
    name: Localization.getInst().localizedString(LocalizationKey.averageWeightStats),
    lineChartProps: { color: '#f032e6', legendType: 'plainline' },
    aggregateRule: 'avg'
  },
  {
    id: 'weight_distribution',
    name: Localization.getInst().localizedString(LocalizationKey.weightDistribution),
    aggregateRule: 'custom',
    customAggregateRule: (acc2: WeightDistributionRow[], next2: WeightDistributionRow[]) =>
      [...(acc2 || []), ...(next2 || [])]
        .sort((a, b) => a.bucket - b.bucket)
        .reduce((acc, item, index) => {
          if (index > 0 && acc[acc.length - 1].bucket === item.bucket) {
            acc[acc.length - 1].count += item.count;
          } else acc.push({ ...item });
          return acc;
        }, [] as WeightDistributionRow[])
  }
];
