import React, { useEffect } from "react";
import {
  Locality,
  Cage,
  StatsIntervalStatus,
  Sensor,
} from "../../types/models";
import { CagesGrid } from "../CagesGrid/CagesGrid";
import {
  Card,
  Typography,
  CardMedia,
  CardContent,
  Collapse,
  Fade,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/styles";
import { StatusBox, StatusBoxLocality } from "../StatusBox/StatusBox";
import { Theme as AugmentedTheme } from "@material-ui/core";
import { CagesGridDetails } from "../CagesGrid/Details/Details";
import { Localization } from "../../localization/Localization";
import { LocalizationKey } from "../../localization/LocalizationKey";
import { TTabs } from "../../components/LocalityDetails/LocalityDetails";

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: (props: { cagesLength: number }) => ({
    height: 750,
    width: 330,
    flexShrink: 0,
    boxShadow: "unset",
    background:
      props.cagesLength > 0 ? theme.colors.white08 : theme.colors.white,
    margin: theme.remSpacing(0, 6, 6, 6),
  }),
  titleCardContent: {
    background: theme.colors.white,
    padding: theme.remSpacing(5, 4, 0, 10),
  },
  statusCardContent: {
    background: theme.colors.white,
    padding: theme.remSpacing(0, 4, 5, 10),
  },
  fishWelfareTitle: {
    color: theme.colors.oxfordBlue06,
  },
  mediaBox: {
    height: 100,
    textAlign: "center",
    verticalAlign: "middle",
  },
  mediaImage: {
    height: "100%",
  },
  mediaTextBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  arrowButtonWrapper: {
    textAlign: "center",
    background: theme.colors.white,
  },
  arrowButton: {
    padding: "unset",
    color: theme.colors.oxfordBlue,
  },
}));

interface Props {
  locality: Locality;
  cages: Cage[];
  sensors: Sensor[];
  selectedCage: Cage | undefined;
  setSelectedCage: (value: React.SetStateAction<Cage | undefined>) => void;
  selectCageForLiftup: () => Promise<void>;
  liftupResponse: string;
  tab: TTabs;
  statsInterval: StatsIntervalStatus;
}

// This is the left pane at /localityDetails
export const InfoPanel: React.FunctionComponent<Props> = ({
  locality,
  cages,
  sensors,
  selectedCage,
  setSelectedCage,
  selectCageForLiftup,
  liftupResponse,
  tab,
  statsInterval,
}: Props) => {
  const [expandedCageDetails, setExpandedCageDetails] = React.useState<boolean>(
    false
  );

  const handleCageSelect = (selectedCageId: string) => {
    setSelectedCage(
      cages.find((cage) => {
        return cage.cageId == selectedCageId;
      })
    );

    setExpandedCageDetails(true);
  };

  const handleLocalitySelect = () => {
    setSelectedCage(undefined);
  };

  const toggleCageDetails = () => {
    setExpandedCageDetails(!expandedCageDetails);
  };

  useEffect(() => {
    if (undefined == selectedCage) {
      setExpandedCageDetails(false);
    }
  }, [selectedCage]);

  const styles = useStyles({ cagesLength: cages.length });

  const locationBox = () => {
    return (
      <Box className={styles.mediaBox}>
        {locality.locationImageUrl && (
          <CardMedia
            className={styles.mediaImage}
            image={locality.locationImageUrl}
            title="Location"
          />
        )}
        {locality.locationImageUrl === null && (
          <Box className={styles.mediaTextBox}>
            <Typography variant="h6">
              {Localization.getInst().localizedString(
                LocalizationKey.locationImageNotAvailable
              )}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const sensorId = locality.sensorId;

  return (
    <Card className={styles.container}>
      <CardContent classes={{ root: styles.titleCardContent }}>
        <Typography
          variant="h1"
          className={styles.fishWelfareTitle}
          gutterBottom
        >
          {Localization.getInst().localizedString(
            tab === "welfare"
              ? statsInterval === StatsIntervalStatus.weekly
                ? LocalizationKey.localityDetailsWeeklyFishWelfareIn
                : LocalizationKey.localityDetailsFishWelfareIn
              : LocalizationKey.localityDetailsFishBiomassIn
          )}
        </Typography>
        <Tooltip title={<h3>{sensorId}</h3>} placement="top-start">
          <Typography variant="h1">{locality.name}</Typography>
        </Tooltip>
      </CardContent>
      <Box className={styles.arrowButtonWrapper}>
        <Fade
          in={expandedCageDetails}
          timeout={{
            enter: 1200,
            exit: 300,
          }}
        >
          <IconButton
            className={styles.arrowButton}
            onClick={toggleCageDetails}
          >
            <ExpandMore fontSize="large" />
          </IconButton>
        </Fade>
      </Box>
      {locality.worstCage && (
        <Collapse in={!expandedCageDetails}>
          <CardContent classes={{ root: styles.statusCardContent }}>
            <StatusBoxLocality
              locality={locality}
              statsInterval={statsInterval}
              onLocalityClick={handleLocalitySelect}
            />
          </CardContent>
          {locationBox()}
        </Collapse>
      )}

      {cages.length > 0 && (
        <CagesGrid
          cages={cages}
          handleCageSelect={handleCageSelect}
          toggleCageDetails={toggleCageDetails}
          selectedCage={selectedCage}
          cageDetailsExpanded={expandedCageDetails}
          tab={tab}
        />
      )}

      <Collapse in={expandedCageDetails}>
        {selectedCage && (
          <CagesGridDetails
            cage={selectedCage}
            selectCageForLiftup={selectCageForLiftup}
            liftupResponse={liftupResponse}
            tab={tab}
            statsInterval={statsInterval}
            sensor={sensors.find(
              (sensor) => sensor.cageId === selectedCage.cageId
            )}
          />
        )}

        <Box className={styles.arrowButtonWrapper}>
          <Fade
            in={expandedCageDetails}
            timeout={{
              enter: 1200,
              exit: 300,
            }}
          >
            <IconButton
              className={styles.arrowButton}
              onClick={toggleCageDetails}
            >
              <ExpandLess fontSize="large" />
            </IconButton>
          </Fade>
        </Box>
      </Collapse>
    </Card>
  );
};
