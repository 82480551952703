import React, { useState, FunctionComponent, useEffect } from 'react';
import { DatePicker } from './DatePicker/DatePicker';
import { Button, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme as AugmentedTheme } from '@material-ui/core';
import { ButtonWithValue } from './ButtonWithValue/ButtonWithValue';
import moment, { Moment } from 'moment';
import './MomentExtensions';
import { DayRangeInfo, DayRangeType, DayRange } from './DayRangeSelectorInterface';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { dayRangeLocalizedName } from './DayRangeLocalizationUtils';
import { StatsIntervalStatus } from '../../types/models';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  card: {
    width: 198,
    overflow: 'visible'
  },
  separator: {
    height: 1,
    background: theme.colors.oxfordBlue02
  },
  dateButton: {
    height: theme.remSpacing(10),
    marginLeft: theme.remSpacing(7),
    textTransform: 'none'
  },
  defaultDateButton: {
    height: theme.remSpacing(10),
    marginLeft: theme.remSpacing(7),
    textTransform: 'none',
    textDecoration: 'underline'
  },
  dateBox: {
    paddingLeft: theme.remSpacing(7),
    paddingBottom: theme.remSpacing(2)
  },
  dateLabel: {
    paddingLeft: theme.remSpacing(2)
  }
}));

interface Props {
  initialDayRangeType: DayRangeType;
  customDayRange: DayRange | null;
  statsInterval: StatsIntervalStatus;
  onDayRangeSelect: (dayRangeInfo: DayRangeInfo) => void;
}

export const DayRangeSelector: FunctionComponent<Props> = ({
  initialDayRangeType,
  customDayRange,
  statsInterval,
  onDayRangeSelect
}: Props) => {
  const today = moment();
  const dayRangeForInputArguments = (initialDayRangeType: DayRangeType, customDayRange: DayRange | null): DayRange => {
    switch (initialDayRangeType) {
      case 'Last week':
        return {
          from: moment().lastWeek(),
          to: today
        };
      case 'Last month':
        return {
          from: moment().lastMonth(),
          to: today
        };
      case 'Last 6 months':
        return {
          from: moment().last6Months(),
          to: today
        };
      case 'Last year':
        return {
          from: moment().lastYear(),
          to: today
        };
      case 'Custom':
        return customDayRange != undefined
          ? { from: customDayRange.from, to: customDayRange.to }
          : { from: null, to: null };
    }
    return { from: null, to: null };
  };

  const initialDayRange = dayRangeForInputArguments(initialDayRangeType, customDayRange);

  const initialDayRangeInfo: DayRangeInfo = { type: initialDayRangeType, range: initialDayRange };
  const [selectedDayRangeInfo, setSelectedDayRangeInfo] = useState<DayRangeInfo>(initialDayRangeInfo);

  useEffect(() => {
    onDayRangeSelect(selectedDayRangeInfo);
  }, [selectedDayRangeInfo]);

  const classes = useStyles();

  const clearFromDate = () => {
    setSelectedDayRangeInfo((prevState: DayRangeInfo) => {
      return { ...prevState, range: { ...prevState.range, from: null } };
    });
  };

  const textButtonHandler = (dayRangeInfo: DayRangeInfo) => {
    setSelectedDayRangeInfo(dayRangeInfo);
  };

  const onFromDataChange = (date: Moment | null) => {
    setSelectedDayRangeInfo((prevState: DayRangeInfo) => {
      return { type: 'Custom', range: { ...prevState.range, from: date } };
    });
  };

  const onToDataChange = (date: Moment | null) => {
    // if a predefined data is set (for example Last week, Last month), we need to clear From date.
    if (selectedDayRangeInfo.type != 'Custom') {
      clearFromDate();
    }

    setSelectedDayRangeInfo((prevState: DayRangeInfo) => {
      return { type: 'Custom', range: { ...prevState.range, to: date } };
    });
  };

  const calendarInitialDate = (): DayRange => {
    if (selectedDayRangeInfo.type != 'Custom') {
      return { from: null, to: null };
    }

    return selectedDayRangeInfo.range;
  };

  const calendarAllowedDaysRange = (): DayRange => {
    if (selectedDayRangeInfo.type != 'Custom') {
      return { from: null, to: today };
    }

    return selectedDayRangeInfo.range;
  };

  const dayRanges: DayRangeInfo[] = [
    { type: 'Last week', range: { from: moment().lastWeek(), to: today } },
    { type: 'Last month', range: { from: moment().lastMonth(), to: today } },
    { type: 'Last 6 months', range: { from: moment().last6Months(), to: today } },
    { type: 'Last year', range: { from: moment().lastYear(), to: today } }
  ];

  return (
    <Card className={classes.card}>
      <div>
        <Button className={classes.defaultDateButton}>
          <Typography variant="h3" component="span">
            {dayRangeLocalizedName(selectedDayRangeInfo.type)}
          </Typography>
          <ExpandMoreIcon />
        </Button>
      </div>
      <div className={classes.separator} />
      {statsInterval === StatsIntervalStatus.daily &&
        dayRanges.map((dayRange) => {
          return (
            <ButtonWithValue
              key={dayRange.type}
              className={classes.dateButton}
              value={dayRange}
              onButtonClick={textButtonHandler}
            >
              <Typography variant="h4" component="span">
                {dayRangeLocalizedName(dayRange.type)}
              </Typography>
            </ButtonWithValue>
          );
        })}

      <div className={classes.dateBox}>
        <div className={classes.dateLabel}>
          <Typography variant="h6" component="span">
            {Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorFrom)}
          </Typography>
        </div>
        <DatePicker
          elementId={'from_day_input'}
          initialDate={calendarInitialDate().from}
          onDateChange={onFromDataChange}
          allowedDaysRange={{ from: null, to: calendarAllowedDaysRange().to }}
          weekdayAllowed={statsInterval === StatsIntervalStatus.weekly ? 1 : false}
        />
      </div>
      <div className={classes.dateBox}>
        <div className={classes.dateLabel}>
          <Typography variant="h6" component="span">
            {Localization.getInst().localizedString(LocalizationKey.dayRangeSelectorTo)}
          </Typography>
        </div>
        <DatePicker
          elementId={'to_day_input'}
          initialDate={calendarInitialDate().to}
          allowedDaysRange={{
            from: calendarAllowedDaysRange().from,
            to: today
          }}
          weekdayAllowed={statsInterval === StatsIntervalStatus.weekly ? 7 : false}
          onDateChange={onToDataChange}
        />
      </div>
    </Card>
  );
};
