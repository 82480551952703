import React, { FunctionComponent, useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker, DayOfWeekShape } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Moment } from 'moment';
import { isBoolean } from 'util';
import './DatePickerStyles.css';
import { DayRange } from '../DayRangeSelectorInterface';

const datePickerSettings = {
  numberOfMonths: 1,
  firstDayOfWeek: 1 as DayOfWeekShape,
  displayFormat: 'DD / MM / YY',
  placeholder: 'dd / mm / yy'
};

interface Props {
  elementId: string;
  initialDate: Moment | null;
  onDateChange: (date: Moment | null) => void;
  allowedDaysRange: DayRange;
  weekdayAllowed: number | false;
}

export const DatePicker: FunctionComponent<Props> = ({
  elementId,
  initialDate,
  onDateChange,
  allowedDaysRange,
  weekdayAllowed
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false);

  const _onDateChange = (date: Moment | null) => {
    onDateChange(date);
  };

  const onFocusChange = (arg: { focused: boolean | null }) => {
    setFocused(isBoolean(arg.focused) ? arg.focused : false);
  };

  const isOutsideRange = (day: Moment) => {
    if (allowedDaysRange.from && day.isSameOrBefore(allowedDaysRange.from, 'day')) {
      return true;
    }

    if (allowedDaysRange.to && day.isAfter(allowedDaysRange.to, 'day')) {
      return true;
    }

    return false;
  };

  return (
    <SingleDatePicker
      {...datePickerSettings}
      id={elementId}
      date={initialDate}
      focused={focused}
      onDateChange={_onDateChange}
      onFocusChange={onFocusChange}
      isOutsideRange={isOutsideRange}
      isDayBlocked={(day: Moment) => (weekdayAllowed ? day.isoWeekday() != weekdayAllowed : false)}
    />
  );
};
