import React, { Fragment } from 'react';
import { Fade, Box } from '@material-ui/core';
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon';

interface Props {
  label?: string;
  icon: JSX.Element;
  currentSlide: number;
  slideCount: number;
  slidesToShow: number;
  direction: 'left' | 'right';
  onButtonClick: () => void;
}

export const CarouselButton: React.FunctionComponent<Props> = ({
  label,
  icon,
  currentSlide,
  slideCount,
  slidesToShow,
  direction,
  onButtonClick
}: Props) => {
  const disabled = () => {
    switch (direction) {
      case 'left':
        return currentSlide === 0;
      case 'right':
        const lastSlideIndex = slideCount - 1;
        return slidesToShow > 1 ? currentSlide + slidesToShow > lastSlideIndex : currentSlide + 1 > lastSlideIndex;
    }
  };
  return (
    <Fragment>
      <Fade
        in={!disabled()}
        timeout={{
          enter: 1200,
          exit: 300
        }}
      >
        <Box>
          <ButtonWithIcon label={label} icon={icon} direction={direction} onButtonClick={onButtonClick} />
        </Box>
      </Fade>
      {disabled() && null}
    </Fragment>
  );
};
