import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { RadioButtonUncheckedOutlined, RadioButtonCheckedOutlined } from '@material-ui/icons';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { Localization } from '../../localization/Localization';
import { StatsIntervalStatus } from '../../types/models';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  labelWrapper: {
    marginRight: theme.remSpacing(5)
  },
  label: {
    fontSize: theme.typography.h6.fontSize
  }
}));

interface Props {
  setStatsInterval: (statsInterval: StatsIntervalStatus) => void;
}

export const StatsIntervalButtons: FunctionComponent<Props> = ({ setStatsInterval }: Props) => {
  const [value, setValue] = useState('daily');

  const handleChange = (event: React.ChangeEvent<unknown>) => {
    const newValue = (event.target as HTMLInputElement).value as StatsIntervalStatus;
    setValue(newValue);
    setStatsInterval(newValue);
  };
  const styles = useStyles();
  return (
    <RadioGroup value={value} onChange={handleChange} row>
      <FormControlLabel
        classes={{ root: styles.labelWrapper, label: styles.label }}
        value="daily"
        control={
          <Radio
            color="primary"
            icon={<RadioButtonUncheckedOutlined fontSize="small" />}
            checkedIcon={<RadioButtonCheckedOutlined fontSize="small" />}
          />
        }
        label={Localization.getInst().localizedString(LocalizationKey.dailyStats)}
        labelPlacement="end"
      />
      <FormControlLabel
        classes={{ root: styles.labelWrapper, label: styles.label }}
        value="weekly"
        control={
          <Radio
            color="primary"
            icon={<RadioButtonUncheckedOutlined fontSize="small" />}
            checkedIcon={<RadioButtonCheckedOutlined fontSize="small" />}
          />
        }
        label={Localization.getInst().localizedString(LocalizationKey.weeklyStats)}
        labelPlacement="end"
      />
    </RadioGroup>
  );
};
